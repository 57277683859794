<template>
    <div>
        <top-nav-comp></top-nav-comp>
        <router-view/> <!-- Initiate Routing Child components -->
        <footer-comp></footer-comp>
    </div>
</template>

<script>
import TopNavComp from './../../containers/TopNav.vue'
import FooterComp from './../../containers/Footer.vue'

export default {
  components: {
    TopNavComp,
    FooterComp
  }
}
</script>